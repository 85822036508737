<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@suaw/suaw-component-library";
import ArticleFrame from "@/features/articles/components/ArticleFrame.vue";

export default {
  name: "PerfectionismDistractsYouFromWritingPerfectly",
  components: {
    SuawMainContent,
    SuawParagraph,
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="PerfectionismDistractsYouFromWritingPerfectly">
      <SuawParagraph
        text="As you’re writing, it’s normal to want your words to be perfectly aligned with your vision; inside all of us is an Inner Editor. This is the voice that listens as we write and pauses us with thoughts like “you can find a better word” or “well that’s not really clear,” or “is that really what you want to say?” I’m experiencing it right now, in fact… it’s very hard to tell that voice “thank you, I’ll come back to you later” and keep writing."
      />
      <SuawParagraph
        text="The thing is, you can’t edit and write at the same time. They involve two different parts of your brain. So if you stop and edit, before you have even a draft of what you are writing, you may find that the process takes longer and you feel frustrated by what is invariably a stop/start to your creative voice — who frankly, just wants to get it all out there and be heard."
      />
      <SuawParagraph text="Here are some tips to help you get past the urge toward perfection." />
      <SuawHeading level="3" content="You cannot learn to fly by reading a book, you have to fly badly at first" />
      <SuawParagraph
        text="Write badly. It’s OK! Remind yourself that you can’t edit if you don’t have the full idea yet. Well you CAN, but chances are you’re likely going to change it all anyway."
      />
      <SuawHeading level="3" content="Avoid premature critiques or any feedback from people who are not your natural audience" />
      <SuawParagraph
        text="Please, please, spare yourself the agony. Give the artist in you time to reflect on your work; give yourself time to see where it goes. The more you write, the deeper you get into your story. Sharing your work before you have your first draft will make you question your characters or your plot and you’ll start to see the holes and ask yourself questions you don’t need to ask yet. Self-doubt can be crippling to a writer. So ask your loved ones, your friends, your colleagues, to please have patience and wait."
      />
      <SuawHeading level="3" content="All art is created by imperfect people" />
      <SuawParagraph
        text="No one is perfect. The work we create is going to be imperfect. That’s why there’s a process! No one gets it right on the first try. Personally, I’m a “fifth” kind of writer."
      />
      <SuawHeading level="3" content="“Perfectionism” means never completing your project" />
      <SuawParagraph
        text="If you focus too much on finding just the right word or getting that historical detail exactly right, you might lose track of where you are in the story. You might get bogged down so much you never make it to the end. So keep writing. Let there be misspellings. Leave things blank that you will go back and fill in later. Trust that you will fill in the holes during editing. And trust me, there is always editing."
      />
      <SuawHeading level="3" content="Art is never finished—the artist must choose to step away" />
      <SuawParagraph
        text="Speaking of editing – after you’ve done your first draft and you’ve spent the hours and days making your story the best you can, pause – go find a reader in your genre. Get it critiqued. Take in the feedback, figure out what needs work and then another round of edits."
      />
      <SuawHeading level="3" content="You could probably edit forever. There is such a thing as too much." />
      <SuawParagraph
        text="Writing is a creative process and you are an artist. The work may never be as perfect as you want. Many writers, even after their work has been published and applauded by readers, has things in their story they want to go back and fix. When it’s time, decide you’ve done your best and either publish or start the next project, preferably both."
      />
      <SuawParagraph
        text="I hope these were helpful tips! Let me know what helps you deal with your Inner Editor. As art instructor Howard Ikamoto would say, “Work it until it is complete, but leave it alone before it becomes ‘precious.’”"
      />
    </ArticleFrame>
  </SuawMainContent>
</template>
